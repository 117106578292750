@font-face {
  font-family: "icon";
  src: url( "../fonts/icon.eot" );
  src: url( "../fonts/icon.eot?#iefix" ) format( "eot" ),
       url( "../fonts/icon.ttf" ) format( "truetype" ),
       url( "../fonts/icon.woff" ) format( "woff" ),
       url( "../fonts/icon.svg#icon" ) format( "svg" );
  font-weight: normal;
  font-style: normal;
}

.icon-base,
[class^="icon-"], [class*=" icon-"] {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before { content: "\EA01" }
.icon-arrow-left:before { content: "\EA02" }
.icon-arrow-right:before { content: "\EA03" }
.icon-arrow-up:before { content: "\EA04" }
.icon-flicker:before { content: "\EA05" }
.icon-mail:before { content: "\EA06" }
