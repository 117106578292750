.hamburger {
    @extend %inlineblock;
    cursor: pointer;
    z-index: 300;
    transition: opacity 0.3s;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    overflow: visible;
    @mixin media above-desktop {
        &:hover {
            @if $hamburger-hover-use-filter == true {
                filter: $hamburger-hover-filter;
            }
            @else {
                opacity: 0.7;
            }
        }
    }
    @mixin media above-tablet {
        padding: 20px;
    }
    @mixin media below-tablet {
        padding: 15px;
    }
}

.hamburger-box {
    display: inline-block;
    position: relative;
    @mixin media above-tablet {
        width: 30px;
        height: 26px;
    }
    @mixin media below-tablet {
        width: 25px;
        height: 22px;
    }
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    &,
    &::before,
    &::after {
        background-color: $red;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
        @mixin media above-tablet {
            width: 30px;
            height: 4px;
        }
        @mixin media below-tablet {
            width: 25px;
            height: 3px;
        }
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }
    &::before {
        @mixin media above-tablet {
            top: -10px;
        }
        @mixin media below-tablet {
            top: -9px;
        }
    }
    &::after {
        bottom: -8px;
    }
}

.hamburger--collapse {
    .hamburger-inner {
        top: auto;
        bottom: 0;
        transition-duration: 0.15s;
        transition-delay: 0.15s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::after {
            transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
            @mixin media above-tablet {
                top: -20px;
            }
            @mixin media below-tablet {
                top: -18px;
            }
        }

    &::before {
      transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                  transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
      transition-delay: 0.32s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::after {
        top: 0;
        opacity: 0;
        transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    opacity 0.1s 0.27s linear;
      }

      &::before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
