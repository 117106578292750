.bottom-banner {
    @extend %position-relative;
    @extend %full-width;
    @extend %background-cover;
    height: 250px;
}

.bottom-banner__container {
    @extend %position-absolute;
    @extend %full-width;
    @extend %full-height;
    @extend %flex-center;
    @extend %text--center;
    color: white;
    top: 0;
    left: 0;
    background-color: rgba($black, 0.4);
}

.bottom-banner__heading {
    @extend %font-heading--l;
    margin-bottom: 20px;
}

.bottom-banner__paragraph {
    @extend %font-paragraph--m;
    margin-bottom: 30px;
}
