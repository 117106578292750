.full-two-blocks__wrap {
    @extend %position-relative;
    lost-utility: clearfix;
    background-color: $white-light;
    @mixin media above-tablet {
        height: 60vh;
        &.reverse {
            .full-two-blocks__image {
                lost-move: 1/2 2 0;
            }
            .full-two-blocks__container {
                lost-move: 0;
            }
        }
    }
    @mixin media below-tablet {
        height: auto;
    }
}

.full-two-blocks__image {
    @extend %background-cover;
    @mixin media above-tablet {
        lost-column: 1/2 2 0;
        height: 100%;
    }
    @mixin media below-tablet {
        lost-column: 1/1;
        height: calc(100vw / 3 * 2);
    }
}

.full-two-blocks__container-wrap {
    @extend %full-width;
    @extend %flex-center;
    @mixin media above-tablet {
        position: absolute;
        height: 100%;
    }
    @mixin media below-tablet {
        position: relative;
        height: auto;
    }
}

.full-two-blocks__container {
    @mixin media above-tablet {
        lost-column: 1/2 2 10%;
        lost-move: 1/2 2 10%;
    }
    @mixin media below-tablet {
        lost-column: 1/1;
        lost-move: 0;
        padding: 20px 0;
    }
}
