/*lost-column setting*/
@lost gutter 20px;

body, html {
    font-family: $source-sans;
    color: $black;
    overflow-x: hidden;
    &.is-fixed {
		overflow: hidden;
	}
}

a, button {
    cursor: pointer;
    text-decoration: none;
    /*color: $red;*/
}

img {
    @extend %full-width;
    @extend %block;
}

.lazyload {
    opacity: 0;
    transition-delay: 0.5s;
    transition: opacity 0.3s;
}

.b-loaded {
    opacity: 1;
}
