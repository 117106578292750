.three-columns-carousel__item {
    padding: 0 5px;
    transition: opacity 0.3s;
    @mixin media below-tablet {
        opacity: 0.6;
        &.slick-current {
            opacity: 1;
        }
    }
}

.three-columns-carousel__image-wrap {
    @extend %block;
    overflow: hidden;
}

.three-columns-carousel__link {
    @extend %block;
    @mixin media above-desktop {
        &:hover {
            .three-columns-carousel__image {
                transform: scale(1.05);
            }
        }
    }
    @mixin media below-tablet {
        pointer-events: none;
        .slick-current & {
            pointer-events: auto;
        }
    }
}

.three-columns-carousel__image {
    transition: transform 0.5s;
    -webkit-backface-visibility: hidden;
}

.three-columns-carousel__heading {
    @extend %inline--middle;
    @extend %font-paragraph--l;
    color: $gray;
    @mixin media above-tablet {
        display: inline-block;
        vertical-align: moddle;
    }
    @mixin media below-tablet {
        display: block;
    }
}

.three-columns-carousel__subheading {
    @extend %inline--middle;
    @extend %font-paragraph--s;
    color: $gray-light;
    font-style: italic;
    @mixin media above-tablet {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }
    @mixin media below-tablet {
        display: block;
        margin-left: 0;
        span {
            display: none;
        }
    }
}

.three-columns-carousel__container {
    transition: opacity 0.3s;
    @mixin media above-tablet {
        padding: 5px 0;
    }
    @mixin media below-tablet {
        padding: 8px 0;
        text-align: center;
        opacity: 0;
        .slick-current & {
            opacity: 1;
        }
    }
}

.three-columns-carousel__btn {
    @extend %position-absolute;
    z-index: 5;
    @mixin media above-desktop {
        &:hover {
            .three-columns-carousel__icon {
                color: $red-light;
            }
        }
    }
    @mixin media below-small {
        background-color: $cream;
    }
    @mixin media above-tablet {
        padding: 15px;
        top: 39%;
    }
    @mixin media below-tablet {
        padding: 10px;
        top: 32%;
    }
    &.btn--prev {
        @mixin media above-small {
            left: -50px;
        }
        @mixin media below-small {
            left: -1px;
        }
        @mixin media below-small {
            left: 5px;
        }
    }
    &.btn--next {
        @mixin media above-small {
            right: -50px;
        }
        @mixin media below-small {
            right: -1px;
        }
        @mixin media below-small {
            right: 5px;
        }
    }
}

.three-columns-carousel__icon {
    @extend %block;
    transition: color 0.3s;
    @mixin media above-tablet {
        font-size: 20px;
    }
    @mixin media below-tablet {
        font-size: 15px;
    }
}
