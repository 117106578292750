.works-section {
    background-color: $white-light;
    min-height: 60vh;
    @mixin media above-tablet {
        padding: 60px 0;
    }
    @mixin media below-tablet {
        padding: 30px 0;
    }
}

.works-section__filter {
    @mixin media above-tablet {
        lost-column: 1/4;
    }
    @mixin media below-tablet {
        lost-column: 1/1;
    }
}

.works-section__works {
    @mixin media above-tablet {
        lost-column: 3/4;
    }
    @mixin media below-tablet {
        lost-column: 1/1;
    }
}

.work-info {
    padding: 40px 0;
    border-top: 1px solid $white-light;
}

.work-info__block {
    @mixin media above-tablet {
        lost-column: 1/4;
    }
    @mixin media below-tablet {
        lost-column: 1/1;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.work-info__heading {
    @extend %font-paragraph--l;
    font-weight: 700;
    @mixin media above-tablet {
        margin-bottom: 10px;
    }
    @mixin media below-tablet {
        margin-bottom: 5px;
    }
}

.work-info__item {
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0;
    }
}
