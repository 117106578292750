.main-menu {
    @extend %position-fixed;
    @extend %full-width;
    @extend %full-height;
    @extend %opacity-hidden;
    transform: translateY(30px);
    top: 0;
    left: 0;
    z-index: 300;
    background-color: white;
    @mixin media above-tablet {
        padding: 30px;
    }
    @mixin media below-tablet {
        padding: 15px;
    }
    &.is-active {
        transform: translateY(0);
    }
}

.main-menu__container {
    @extend %full-height;
    @extend %flex-center;
    padding: 30px;
    background-color: $cream;
}

.main-menu__list {
    @extend %full-width;
    lost-utility: clearfix;
}

.main-menu__item {
    @extend %position-relative;
    @extend %text--center;
    @mixin media below-desktop {
        .effect-takiri__heading {
            padding: 20px 0;
        }
    }
    @mixin media above-small {
        lost-column: 1/3;
    }
    @mixin media below-small {
        lost-column: 1/1;
        padding: 40px 0;
        .effect-takiri__subheading {
            display: none;
        }
    }
    @mixin media below-tablet {
        padding: 20px 0;
    }
    &:after {
        position: absolute;
        background-color: $gray-light;
        content: '';
        transform: rotate3d(0,0,1,45deg);
        height: 1px;
        @mixin media above-small {
            top: 40%;
            right: -45px;
        }
        @mixin media below-small {
            top: auto;
            bottom: 0;
            right: 50%;
            margin-right: -30px;
        }
        @mixin media above-tablet {
            width: 60px;
        }
        @mixin media below-tablet {
            width: 40px;
            margin-right: -20px;
        }
    }
    &:nth-child(3n) {
        &:after {
            display: none;
        }
    }
}

.effect-takiri {
    @extend %position-relative;
    @extend %block;
    @mixin media above-desktop {
        &:hover {
            .effect-takiri__heading {
                color: $red;
            }
            .effect-takiri__subheading {
                opacity: 1;
            	transform: translate3d(0,0,0);
            }
            &:before {
            	transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
            }
        }
    }
    &:before {
        content: '';
    	position: absolute;
    	height: 5px;
    	width: 170px;
    	top: 50%;
    	left: 50%;
    	z-index: 0;
        margin-left: -85px;
    	background: #F9F9F9;
    	transform: rotate3d(0,0,1,45deg) scale3d(0,1,1);
    	transition: transform 0.5s;
    }
}

.effect-takiri__heading {
    @extend %text--center;
    @extend %position-relative;
    @extend %block;
    @extend %font-menu-heading;
    color: $red-light;
	padding: 0 10px 10px;
	transition: color 0.5s;
    line-height: 1;
}

.effect-takiri__subheading {
    @extend %font-menu-subheading;
    @extend %block;
    @extend %text--center;
	color:  $gray-light;
	opacity: 0;
	transform: translate3d(-10px,-10px,0);
	transition: transform 0.5s, opacity 0.5s;
}
