%wrapper-style {
	@extend %clear;
	@extend %full-width;
	margin: 0 auto;
	@mixin media above-tablet {
        padding: 0 30px;
    }
    @mixin media below-tablet {
        padding: 0 15px;
    }
}

.wrapper-l {
	@extend %wrapper-style;
	max-width: $l-wrap;
}

.wrapper-m {
	@extend %wrapper-style;
	max-width: $md-wrap;
}

.wrapper-s {
	@extend %wrapper-style;
	max-width: $sm-wrap;
}
