@define-mixin media $point {
	@if $point == above-large {
	  @media (min-width: 1401px) { @mixin-content; }
	}
	@if $point == below-large {
	  @media (max-width: 1400px) { @mixin-content; }
	}
	@if $point == above-medium {
	  @media (min-width: 1281px) { @mixin-content; }
	}
	@if $point == below-medium {
	  @media (max-width: 1280px) { @mixin-content; }
	}
	@if $point == above-desktop {
	  @media (min-width: 1025px)  { @mixin-content; }
	}
	@if $point == below-desktop {
	  @media (max-width: 1024px)  { @mixin-content; }
	}
	@if $point == above-small {
	  @media (min-width: 851px)  { @mixin-content; }
	}
	@if $point == below-small {
	  @media (max-width: 850px)  { @mixin-content; }
	}
	@if $point == above-tablet {
	  @media (min-width: 768px)  { @mixin-content; }
	}
	@if $point == below-tablet {
	  @media (max-width: 767px)  { @mixin-content; }
	}
	@if $point == below-mobile {
	  @media (max-width: 550px)  { @mixin-content; }
	}
}
