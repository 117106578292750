.events-card {
    @extend %position-relative;
    @mixin media above-tablet {
        margin-bottom: 60px;
        lost-column: 1/2 2 2px;
        &:nth-last-child(-n+2) {
            margin-bottom: 0;
        }
    }
    @mixin media below-tablet {
        margin-bottom: 30px;
        lost-column: 1/1;
        &:last-child {
            margin-bottom: 0;
        }
    }

}

.events-card__link {
    @extend %block;
    background-color: white;
    color: $red;
    transition: background-color 0.5s;
    @mixin media above-desktop {
        &:hover {
            background-color: rgba(255,255,255, 0.8);
            .events-card__img {
                transform: scale(1.01);
            }
        }
    }
    @mixin media above-tablet {
        padding: 40px 60px 30px;
    }
    @mixin media below-tablet {
        padding: 40px 35px 20px;
    }
}

.events-card__top {
    @extend %position-absolute;
    @extend %full-width;
    left: 0;
    @mixin media above-small {
        top: -20px;
    }
    @mixin media below-small {
        top: -13px;
    }
    @mixin media above-tablet {
        padding: 0 60px;
    }
    @mixin media below-tablet {
        padding: 0 35px;
    }
}

.events-card__date {
    @extend %inline--middle;
    @extend %font-heading--l;
    width: calc(100% - 65px);
}

.events-card__type {
    @extend %inline--middle;
    @extend %font-paragraph--m;
    @extend %text--right;
    text-transform: uppercase;
    font-weight: 600;
    transform: translateY(5px);
    width: 60px;
}

.events-card__img-wrap {
    overflow: hidden;
    margin-bottom: 25px;
}

.events-card__img {
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.05);
    transition: transform 0.5s;
}

.events-card__heading {
    @extend %font-paragraph--m;
    height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}
