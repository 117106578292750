/* ------------------------------------
*  Width and Height
* ------------------------------------ */

$sm-wrap: 960px;
$md-wrap: 1140px;
$l-wrap: 1280px;

/* ------------------------------------
*  Color
* ------------------------------------ */

$gray: #474642;
$gray-light: #9D9D9D;
$gray-lightest: #ECECEC;
$red: #672525;
$red-light: #B26969;
$black: #272625;
$white-light: #EFF4F4;
$cream: #F2ECE6;

/* ------------------------------------
*  Font
* ------------------------------------ */

$play-fair: 'Playfair Display', serif, '微軟正黑體', 'Microsoft JhengHei',Helvetica, Arial, sans-serif;
$source-sans: 'Source Sans Pro', sans-serif, '微軟正黑體', 'Microsoft JhengHei',Helvetica, Arial, sans-serif;
