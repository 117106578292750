footer {
    @extend %clear;
    background-color: $red;
    color: white;
    @mixin media above-tablet {
        padding: 15px 30px;
    }
    @mixin media below-tablet {
        padding: 15px;
    }
}

.footer__copyright {
    @extend %font-paragraph--s;
    @extend %inline--middle;
    color: $red-light;
    font-size: 10px;
    font-weight: 300;
    width: calc(100% - 90px);
}

.footer__social {
    @extend %inline--middle;
    width: 85px;
}

.footer__social-item {
    @extend %inline--middle;
    margin-left: 10px;
}

.footer__social-link {
    @extend %block;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid white;
    transition: color 0.3s, border 0.3s;
    @mixin media above-desktop {
        &:hover {
            border: 2px solid $red-light;
            color: $red-light;
        }
    }
}

.footer__social-icon {
    @extend %block;
    @extend %full-width;
    @extend %text--center;
    padding: 5px 0;
}
