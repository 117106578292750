%font-menu-heading {
    font-family: $play-fair;
    font-style: italic;
    font-weight: 700;
    @mixin media above-desktop {
        font-size: 40px;
    }
    @mixin media below-desktop {
        font-size: 30px;
    }
    @mixin media below-tablet {
        font-size: 25px;
    }
}

%font-menu-subheading {
    font-family: $play-fair;
    font-style: italic;
    font-size: 20px;
    font-weight: 400;
}

%font-heading--xl {
    font-family: $play-fair;
    font-style: italic;
    letter-spacing: 2px;
    line-height: 1.3em;
    font-weight: 700;
    @mixin media above-tablet {
        font-size: 30px;
    }
    @mixin media below-tablet {
        font-size: 24px;
    }
}

%font-heading--l {
    font-family: $play-fair;
    font-style: italic;
    letter-spacing: 2px;
    line-height: 1.3em;
    font-weight: 700;
    @mixin media above-small {
        font-size: 24px;
    }
    @mixin media below-small {
        font-size: 18px;
    }
}

%font-paragraph--l {
    font-family: $source-sans;
    line-height: 1.5em;
    @mixin media above-tablet {
        font-size: 16px;
    }
    @mixin media below-tablet {
        font-size: 14px;
    }
}

%font-paragraph--m {
    font-family: $source-sans;
    font-size: 14px;
    line-height: 1.5em;
}

%font-paragraph--s {
    font-family: $source-sans;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1.3em;
}
