.bottom-section {
    border-top: 1px solid $gray-lightest;
    @mixin media above-tablet {
        padding-top: 40px;
    }
    @mixin media below-tablet {
        padding-top: 20px;
    }
    .effect-takiri__heading {
        @mixin media below-desktop {
            color: $red;
        }
        @mixin media above-tablet {
            font-size: 30px;
        }
        @mixin media below-tablet {
            font-size: 20px;
        }
    }
    .effect-takiri__subheading {
        font-family: $source-sans;
        font-size: 18px;
        @mixin media below-desktop {
            opacity: 1;
            transform: translate3d(0,0,0);
        }
    }
}

.bottom-section__wrap {
    lost-utility: clearfix;
    background-color: $white-light;
}

.bottom-section__block {
    @extend %background-cover;
    @mixin media above-tablet {
        lost-column: 1/2 2 0;
        height: 30vh;
    }
    @mixin media below-tablet {
        lost-column: 1/1;
        height: 35vh;
        &:first-child {
            margin-bottom: 1px;
        }
    }
}

.bottom-section__link {
    @extend %full-height;
    @mixin media above-desktop {
        &:hover {
            .bottom-section__container {
                opacity: 1;
            }
        }
    }
}

.bottom-section__container {
    @extend %flex-center;
    background-color: rgba($white-light, 0.8);
    transition: 0.3s;
    position: absolute;
    top: 20px;
    left: 20px;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    @mixin media above-desktop {

        opacity: 0;
    }
    @mixin media below-desktop {
        opacity: 1;
    }
}
