.works-list {
    lost-utility: clearfix;
}

.works-list__item {
    @extend %position-relative;
    float: left!important;
    clear: none!important;
    margin-bottom: 20px;
    @mixin media below-desktop {
        border-bottom: 1px solid $gray-light;
    }
    @mixin media above-tablet {
        lost-column: 1/2 2;
    }
    @mixin media below-tablet {
        lost-column: 1/1;
    }
    &.mix {
        display: none;
    }
}

.works-list__img {
    @extend %full-width;
    @extend %block;
}

.works-list__link {
    @mixin media above-desktop {
        &:hover {
            .works-list__container {
                opacity: 1;
            }
        }
    }
}

.works-list__container {
    display: flex;
	align-items: center;
    transition: opacity 0.3s;
    left: 0;
    @mixin media above-desktop {
        justify-content: center;
        background-color: rgba($cream, 0.9);
        position: absolute;
        opacity: 0;
        top: 20px;
        left: 20px;
        height: calc(100% - 40px);
        width: calc(100% - 40px);
    }
    @mixin media below-desktop {
        background-color: transparent;
        position: relative;
        width: 100%;
        opacity: 1;
        top: auto;
        bottom: auto;
        height: auto;
        justify-content: left;
        padding: 10px 3px;
    }
    .effect-takiri__heading {
        @extend %font-heading--l;
        padding-bottom: 5px;
        @mixin media below-desktop {
            color: $red;
            padding: 0 0 10px;
            text-align: left;
        }
    }
    .effect-takiri__subheading {
        @extend %font-paragraph--l;
        @mixin media below-desktop {
            opacity: 1;
            transform: translate3d(0,0,0);
            text-align: left;
        }
    }
}
