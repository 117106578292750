.center-block {
    @extend %text--center;
    background-color: white;
    @mixin media above-tablet {
        padding: 60px;
    }
    @mixin media below-tablet {
        padding: 30px 20px;
    }
}

.center-block__heading {
    @extend %font-heading--l;
    color: $red;
    @mixin media above-tablet {
        margin-bottom: 60px;
    }
    @mixin media below-tablet {
        margin-bottom: 30px;
    }
}

.center-block__img {
    @extend %full-width;
    @extend %block;
    margin-bottom: 40px;
}

.center-block__container {
    max-width: 760px;
    margin: 0 auto;
}

.center-block__paragraph {
    @extend %font-paragraph--m;
    margin-bottom: 40px;
}

.center-block__item {
    @extend %font-paragraph--s;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
