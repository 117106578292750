.grid-section {
    padding: 60px 0;
    .hero-banner + & {
        padding-top: 0;
    }
}

.masonry-grid__item {
    @mixin media above-small {
        lost-column: 1/3 3 0;
    }
    @mixin media below-small {
        lost-column: 1/2 2 0;
    }
    @mixin media above-tablet {
        padding: 2px;
    }
    @mixin media below-tablet {
        lost-column: 1/1;
        padding: 0 0 4px;
        &:last-child {
            padding: 0;
        }
    }
}

.masonry-grid__image-thumb {
    transition: transform 0.5s;
    -webkit-backface-visibility: hidden;
}

.masonry-grid__image {
    @extend %block;
    overflow: hidden;
    @mixin media above-desktop {
        &:hover {
            .masonry-grid__image-thumb {
                transform: scale(1.05);
            }
        }
    }
}
