.main-logo {
    @extend %position-fixed;
    z-index: 500;
    @mixin media above-tablet {
        top: 60px;
        left: 60px;
    }
    @mixin media below-tablet {
        top: 30px;
        left: 30px;
    }
    &__link {
        @extend %block;
        width: 65px;
        height: 65px;
        background-image: url('../img/logo.svg');
        background-size: 65px 65px;
        background-repeat: no-repeat;
        text-indent: -9000px;
    }
}

.menu-trigger {
    @extend %position-fixed;
    z-index: 500;
    transition: top 0.3s, background-color 0.3s;
    @mixin media above-tablet {
        top: 50px;
        right: 40px;
    }
    @mixin media below-tablet {
        top: 36px;
        right: 30px;
    }
    &.position-up {
        background-color: rgba($cream,0.4);
    }
}
