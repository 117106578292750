.two-blocks-carousel {
    @extend %position-relative;
}

.two-blocks-carousel__btn {
    @extend %position-absolute;
    padding: 15px;
    top: 39%;
    @mixin media above-desktop {
        &:hover {
            .two-blocks-carousel__icon {
                color: $red-light;
            }
        }
    }
    @mixin media below-small {
        background-color: rgba($cream, 0.6);
    }
    &.btn--prev {
        @mixin media above-small {
            left: -50px;
        }
        @mixin media below-small {
            left: -1px;
        }
    }
    &.btn--next {
        @mixin media above-small {
            right: -50px;
        }
        @mixin media below-small {
            right: -1px;
        }
    }
}

.two-blocks-carousel__icon {
    font-size: 20px;
    transition: color 0.3s;
}
