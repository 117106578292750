.section-wrap {
    @mixin media above-tablet {
        padding: 80px 0;
    }
    @mixin media below-tablet {
        padding: 40px 0;
    }
}

.section--gray {
    background-color: $white-light;
}

.section-title {
    @extend %text--center;
    @mixin media above-tablet {
        margin-bottom: 40px;
    }
    @mixin media below-tablet {
        margin-bottom: 20px;
    }
}

.section-title__heading {
    @extend %font-heading--l;
    color: $red;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
}

.section-title__subheading {
    @extend %font-paragraph--l;
}
