.filter-list {
    lost-utility: clearfix;
    @mixin media below-tablet {
        margin-bottom: 20px;
    }
}
.filter-list__item {
    margin-bottom: 10px;
    @mixin media below-tablet {
        lost-column: 1/2;
    }
    &.active {
        .filter-list__trigger {
            &:after {
                width: 100%;
            }
        }
    }
}

.filter-list__trigger {
    @extend %position-relative;
    @extend %font-paragraph--m;
    @extend %block;
    padding: 5px 0;
    font-weight: 600;
    transition: color 0.3s;
    &:after {
        @extend %position-absolute;
        content: '';
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: $red;
        width: 0;
        transition: width 0.3s;
    }
    @mixin media above-desktop {
        &:hover {
            color: $red;
            &:after {
                width: 100%;
            }
        }
    }
}
