.banner-section {
    @extend %position-relative;
    @extend %background-cover;
    @extend %full-width;
    @extend %flex-center;
    color: #fff;
    height: 60vh;
    &:before {
        @extend %position-absolute;
        @extend %full-width;
        @extend %full-height;
        background-color: rgba(0,0,0,0.4);
        content: "";
        top: 0;
        left: 0;
    }
    .wrapper-s {
        z-index: 2;
    }
}

.banner-section__container {
    @mixin media below-tablet {
        text-align: center;
    }
}

.banner-section__heading {
    @extend %font-heading--l;
    @mixin media above-tablet {
        margin-bottom: 30px;
    }
    @mixin media below-tablet {
        margin-bottom: 15px;
    }
}

.banner-section__paragraph {
    @extend %font-paragraph--l;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
