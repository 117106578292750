.hero-banner {
    @extend %full-width;
    background-color: white;
    @mixin media above-tablet {
        padding: 30px;
    }
    @mixin media below-tablet {
        padding: 15px;
    }
    .slick-list,
    .slick-track {
        @extend %full-height;
    }

}

.hero-banner__item {
    @extend %position-relative;
    @extend %background-cover;
    @extend %full-height;
    &.color--white {
        color: white;
    }
}

.hero-banner--full {
    @extend %full-view-height;
    .hero-banner__heading {
        @mixin media below-tablet {
            text-shadow: 1px 1px 5px $cream;
        }
    }
}

.hero-banner--half {
    height: 60vh;
    .hero-banner__item {
        &:before {
            @extend %position-absolute;
            @extend %full-width;
            @extend %full-height;
            background-color: rgba(0,0,0,0.3);
            top: 0;
            left: 0;
            content: '';
        }
    }
}

.hero-banner__container {
    @extend %position-relative;
    @extend %full-width;
    @extend %full-height;
    @extend %flex-center;
    top: 0;
    left: 0;
    @mixin media below-tablet {
        text-align: center;
    }
    .half-block {
        @mixin media below-desktop {
            lost-move: 1/12;
        }
        @mixin media above-tablet {
            lost-column: 1/3;
        }
        @mixin media below-tablet {
            lost-move: 0;
            lost-column: 1/1;
        }
    }
    &.position-right {
        .half-block {
            lost-move: 2/3 0 0;
        }
    }
}

.hero-banner__heading {
    @extend %font-heading--xl;
}

.hero-banner__arrows {
    @extend %position-absolute;
    top: 50%;
    z-index: 20;
    padding: 20px;
    @mixin media above-desktop {
        &:hover {
            i {
                color: $red;
            }
        }
    }
    i {
        font-size: 30px;
        color: $red-light;
        transition: color 0.3s;
    }
    &.slick-next {
        right: 40px;
    }
    &.slick-prev {
        left: 40px;
    }
}

.hero-banner__scroll-btn {
    @extend %position-absolute;
    width: 26px;
    height: 26px;
    left: 50%;
    margin-left: -13px;
    animation-name: arrow-move;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    @mixin media above-desktop {
        &:hover {
            .hero-banner__icon {
                color: $red-light;
            }
        }
    }
    @mixin media above-tablet {
        bottom: 50px;
    }
    @mixin media below-tablet {
        bottom: 30px;
    }
}

.hero-banner__icon {
    font-size: 26px;
    color: $red;
    transition: color 0.3s;
}

@keyframes arrow-move {
    0%   {transform: translateY(0);}
    50%  {transform: translateY(-10px);}
    100% {transform: translateY(0);}
}
