.two-blocks-section {
    @extend %clear;
    &.bg-gray {
        background-color: $white-light;
    }
    &:first-of-type {
        padding-top: 0;
    }
    .two-blocks-section__wrap {
        &:nth-child(2n) {
            .two-blocks-section__block {
                &:first-child {
                    lost-move: 1/2 0 0;
                }
                &:last-child {
                    lost-move: -1/2 0 0;
                }
            }
        }
    }
}

.two-blocks-section--square {
    .two-blocks-section__block {
        &:before{
        	padding-top: 100%; 	/* initial ratio of 1:1*/
        }
    }
}

.two-blocks-section--rectangle {
    .two-blocks-section__block {
        &:before {
            padding-top: 67%; 	/* initial ratio of 1:1*/
        }
        &:last-child {
            &:before {
                @mixin media below-small {
                    padding-top: 0;
                }
            }
        }
    }
}

.two-blocks-section__wrap {
    lost-utility: clearfix;
    margin-bottom: 40px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.two-blocks-section__block {
    @extend %position-relative;
    @extend %flex-center;
    background-color: $white-light;
    @mixin media above-small {
        lost-column: 1/2 2 0;
    }
    @mixin media below-small {
        lost-column: 1/1;
    }
    &:before{
        content: "";
        display: block;
    }
}

.two-blocks-section__image {
    @extend %background-cover;
    @extend %position-absolute;
    @extend %full-width;
    @extend %full-height;
    left: 0;
}

.two-blocks-section__container {
    color: $black;
    @mixin media above-tablet {
        padding: 5% 10%;
    }
    @mixin media below-tablet {
        padding: 20px;
    }
}


.two-blocks-section__heading {
    @extend %font-heading--l;
    margin-bottom: 20px;
}

.two-blocks-section__subheading {
    @extend %font-paragraph--l;
    font-weight: 600;
}

.two-blocks-section__line {
    border: 0;
    border-bottom: 1px solid $red-light;
    width: 50px;
    margin: 20px 0;
}

.two-blocks-section__paragraph {
    @extend %font-paragraph--m;
}
