.image-gallery {
    lost-utility: clearfix;
    background-color: white;
    @mixin media above-tablet {
        padding: 30px;
    }
    @mixin media below-tablet {
        padding: 15px;
    }
}

.image-gallery__item {
    overflow: hidden;
    cursor: pointer;
    @mixin media above-desktop {
        &:hover {
            .image-gallery__thumb {
                transform: scale(1);
            }
        }
    }
    @mixin media above-small {
        lost-column: 1/4 4 30px;
        &:nth-last-child(-n+4) {
            margin-bottom: 0;
        }
    }
    @mixin media below-small {
        lost-column: 1/3 3 30px;
        &:nth-last-child(-n+3) {
            margin-bottom: 0;
        }
    }
    @mixin media above-tablet {
        margin-bottom: 30px;
    }
    @mixin media below-tablet {
        lost-column: 1/2 2 15px;
        margin-bottom: 15px;
        &:nth-last-child(-n+2) {
            margin-bottom: 0;
        }
    }

}

.image-gallery__thumb {
    backface-visibility: hidden;
    transition: transform 0.3s;
    transform: scale(1.05);
}
