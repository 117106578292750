%border-btn-style {
    @extend %position-relative;
    @extend %text--center;
    @extend %align--center;
    @extend %inlineblock;
    letter-spacing: 1px;
    overflow: hidden;
    padding: 15px 25px;
    transition: color 0.3s;
    z-index: 1;
    &:after {
        @extend %position-absolute;
        content: "";
        z-index: -1;
        width: 50%;
    	height: 103%;
    	top: 50%;
    	left: 50%;
    	opacity: 0;
        transition: all 0.3s;
        transform: translateX(-50%) translateY(-50%);
    }
}

.border-btn--white {
    @extend %border-btn-style;
    border: 1px solid white;
    color: white;
    &:after {
    	background: #fff;
    }
    @mixin media above-desktop {
        &:hover {
            color: $red;
            &:after {
                width: 101%;
                opacity: 1;
            }
        }
    }
}

.border-btn--gray {
    @extend %border-btn-style;
    border: 1px solid $black;
    color: $black;
    &:after {
    	background: $black;
    }
    @mixin media above-desktop {
        &:hover {
            color: white;
            &:after {
                width: 101%;
                opacity: 1;
            }
        }
    }
}
